import './Banner.css'




export default function Banner(){
    return (
    <div className="Banner">
        <div className="Banner-content">
            <div className="Banner-tWrapper">
                <div className="Banner-text">
                Старт продаж участков! Цена первых 10 по 80.000₽ за сотку.
                </div>
            <div className="Banner-text">
                Старт продаж участков! Цена первых 10 по 80.000₽ за сотку.
            </div>
            </div>
        </div>
    </div>)
}
